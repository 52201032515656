<template>
    <div v-editable="blok" class="mx-auto max-w-full h-auto relative">
        <div>
            <Player
                v-if="muxVideo"
                class="h-full w-full object-cover"
                :video-id="muxVideo.playbackId"
                :aspect-ratio="muxVideo.aspectRatio"
                :play="true"
                :loop="true"
                :controls="false"
                :mute-button-visible="false"
            />
            <video
                v-else-if="videoUrl"
                class="h-full w-full object-cover"
                autoplay="autoplay"
                loop="loop"
                muted="muted"
                playsinline="playsinline"
                preload="none"
            >
                <source :src="videoUrl" type="video/mp4" />
            </video>
            <NuxtImg
                v-else-if="blok.asset.filename"
                class="h-full w-full object-cover"
                :src="blok.asset.filename"
                :alt="blok.title"
                provider="storyblok"
                loading="lazy"
                format="webp"
            />
            <div
                v-if="blok.overlay"
                :style="`background-color: ${blok.overlay}`"
                class="absolute inset-0 mix-blend-multiply"
            />
        </div>
        <div
            class="flex absolute inset-0 sm:flex-col sm:justify-center sm:items-center"
        >
            <div class="mx-auto max-w-2xl text-center flex flex-col space-y-6">
                <div
                    v-if="blok.title"
                    class="relative px-4 py-16 sm:px-6 sm:py-24 lg:px-8 lg:py-32"
                >
                    <h1
                        class="text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl"
                    >
                        <span class="block">{{ blok.title }}</span>
                    </h1>
                </div>
                <!--                <div class="bg-black">Search Bar</div>-->
                <div class="mx-auto max-w-sm">
                    <ButtonContainer :buttons="blok.buttons" />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import type { PropType } from 'vue'
import ButtonContainer from '~/components/Buttons/ButtonContainer.vue'
import Player from '~/components/Player/Player.vue'

const props = defineProps({
    blok: {
        type: Object as PropType<Record<string, any>>,
        required: true,
    },
})

const muxVideo = computed(() => {
    const muxVideoArr = props.blok.mux_video ?? []

    if (muxVideoArr.length === 0) {
        return null
    }

    const muxVideo = muxVideoArr[0]

    return {
        playbackId: muxVideo.playback_id,
        aspectRatio: muxVideo.aspect_ratio ?? null,
    }
})

const videoUrl = computed(() => {
    if (props.blok.asset?.filename?.endsWith('.mp4')) {
        return props.blok.asset.filename
    }

    if (props.blok.video_url) {
        return props.blok.video_url
    }

    return undefined
})
</script>
